import en from './en.json'
import tr from './tr.json'

export default defineI18nConfig(() => {
  const lang = useCookie('locale').value
  return {
    legacy: false,
    locale: lang || import.meta.env.VITE_DEFAULT_LOCALE,
    fallbackLocale: 'tr',
    messages: { en, tr },
    warnHtmlMessage: false,
    missingWarn: false,
    fallbackWarn: false,
  }
})
